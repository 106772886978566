<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pa-5">
            <div class="text-h4 text-center">Update Investment Return</div>
            <v-form ref="form"
            >
              <v-container>
                <v-row class="justify-center ml-5">
                  <v-col cols="12" lg="4">
                    <v-alert type="error" v-if="error">{{ error }}</v-alert>
                    <v-radio-group row v-model="formData.value">
                      <v-radio v-for="(item, index) in radioOptions" :key="index" :value="item.value" :label="item.text"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row class="justify-center" v-if="formData.value === 'project'">
                  <v-col class="d-flex"
                         cols="12"
                         lg="6">
                    <v-select
                        v-model="formData.id"
                        label="Projects"
                        :items="projectOptions"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="justify-center" v-if="formData.value === 'investment'">
                  <v-col class="d-flex"
                         cols="12"
                         lg="6">
                    <v-select
                        v-model="formData.id"
                        label="Investments"
                        :items="investmentOptions"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="formData.value !== ''">
                <v-row class="justify-center">
                  <v-col cols="12" lg="3">
                    <v-text-field
                        v-model="formData.amount"
                        label="Amount"
                        type="number"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="formData.date"
                            label="Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="formData.date"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" lg="6">
                    <v-textarea
                        rows="2"
                        v-model="formData.description"
                        label="Description"
                        type="text"
                        required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="text-center">
                  <v-btn color="primary" class="px-10" @click="updateInvestmentReturn()" :disabled="loading">Update</v-btn>
                  <br>
                  <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mt-5"
                      v-if="loading"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-overlay :value="full_loader" color="white">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
          </v-overlay>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {update_investment_return, edit_investment_return} from "@/apis/investmentReturn";
import {get_all_projects} from "@api/project";
import {get_all_investment_list} from "@api/investment"
export default {
  data() {
    return {
      formData: {
        id: "",
        value: "",
        amount: "",
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        description: "",
      },
      loading: false,
      error: '',
      type: '',
      investmentOptions: [],
      projectOptions: [],
      full_loader: false,
      radioOptions: [
        {
          text: "Ret. of Investment",
          value: "investment",
        },
        {
          text: "Ret. of Project",
          value: "project",
        },
      ],
      errResp:'',
      projectLoading:false,
      investmentLoading:false,
    }
  },
  mounted() {
    this.getInvestmentReturn(this.$route.params.id);
    this.getAllProjects()
    this.getAllInvestments()
  },
  methods: {
    getInvestmentReturn(id) {
      this.full_loader= true;
      edit_investment_return(id).then(
          resp => {
            this.full_loader = false;
            if(resp.status === 'Success'){
              this.formData.id = resp.data.investment_id == null ? resp.data.project_id : resp.data.investment_id;
              this.formData.value = resp.data.investment_id == null ? 'project' : 'investment';
              this.formData.amount = resp.data.amount
              this.formData.date = resp.data.date
              this.formData.description = resp.data.description
            }
            
          }
      )
    },
    getAllProjects() {
      this.projectLoading= true
      this.error=''
      get_all_projects()
      .then(resp => {
          this.projectLoading= false
          if(resp.status === 'Success'){
            this.setProjectOptions(resp.data)
          }else{
            this.error=resp.errors;
          }
        }
      )
    },
    setProjectOptions(data) {
      let list = [];
      data.forEach(p => {
        var obj = {}
        obj['text'] = p.name;
        obj['value'] = p.id;
        list.push(obj);
      });
      this.projectOptions = list
    },
    getAllInvestments(){
      this.investmentLoading=true;
      this.error=''
      get_all_investment_list().then(resp=>{
        this.investmentLoading=false;
        if(resp.status === 'Success'){
          this.setInvestmentOptions(resp.data)
        }else{
          this.error=resp.errors;
        }
      })
    },
    setInvestmentOptions(data) {
      let list = [];
      data.forEach(p => {
        var obj = {}
        obj['text'] = p.ref_name;
        obj['value'] = p.id;
        list.push(obj);
      });
      this.investmentOptions = list
    },
    updateInvestmentReturn() {
      console.log(this.formData);
      this.loading = true;
      this.error = '';
      update_investment_return(this.$route.params.id, this.formData).then(
          resp => {
            this.loading = false;
            if (resp.status == 'Success') {
              this.$toastr.s(resp.message);
              this.$router.push('/InvestmentReturn')
            } else {
              this.error = resp.errors;
            }
          }
      )

    }
  }
}
</script>

<style>

</style>